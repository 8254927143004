@import "../mixins.css";

.guestCheckoutSection {
  max-width: 450px;
  margin: 0 auto;
  padding: $spacingLg $spacingMd 0 $spacingMd;

  .marginTopLg {
    margin-top: $spacingLg;
  }
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}
/* To show the lines on right 
and left sides of the text */
.divider::after,
.divider::before {
  @mixin border_primary__active;
  content: "";
  border-bottom: 0px;
  flex: 1 1 0;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 0.75em;
}

.divider:not(:empty)::after {
  margin-left: 0.75em;
}
