@import "../../mixins.css";
@import "../../media.css";

.component {
  justify-content: center;
  margin: 0 auto $spacingMd;
  max-width: 450px;
  padding: 0 $spacingMd;

  @media (--smartphone) {
    margin: 0 auto;
    width: 100%;
  }

  @media (--desktop) {
    padding: $spacingMd $spacingXl;
    margin: 0;
  }
}

.rightBorder {
  @media (--desktop) {
    border-right: 1px $grey3 solid;
  }
}



.mobileHeading {
  @mixin text_secondary;
  text-align: center;
  display: none;

  @media (--smartphone) {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
}

.drawerHeading {
  @mixin titleText;
  @mixin text_primary;
  text-align: center;
  margin: $spacingMd auto $spacingXs;
  padding: $spacingMd 0;
  border-top: 1px $grey3 solid;

  @media (--smartphone) {
    display: none;
  }
}

.desktopTabletHeading {
  @mixin titleText;
  @mixin text_primary;
  text-align: center;
  margin: $spacingMd auto $spacingXs;
  padding: $spacingMd 0;

  @media (--smartphone) {
    display: none;
  }

  @media (--smartphone-and-tablet) {
    border-top: 1px $grey3 solid;
  }
}

.subHeading {
  @mixin bodyText;
  @mixin text_secondary;
  text-transform: none;
  text-align: center;
  line-height: 1.5;
}

.desktopTabletInfo {
  margin-bottom: $spacingXl;
  text-align: center;

  ul {
    margin-top: $spacingXl;
  }

  li {
    margin: $spacingNudge;
  }

  @media (--smartphone) {
    display: none;
  }
}

.btn {
  @mixin metaTitleText;
  @mixin text_secondary;

  @media (--smartphone) {
    margin-top: $spacingXs;
  }
}

.centerAlign {
  display: flex;
  flex-direction: column;
}

.icon {
  color: $success;
  font-size: $spacingSm;
}
