@import "../../typography.css";
@import "../../mixins.css";

.component {

  .questionText {
    padding-left:12px;
    padding-right:12px;

    .questionTitle {
     @mixin titleText;
     @mixin text_primary;
    }
    .questionDescription {
      @mixin text_primary;
      @mixin paragraphText;
      margin-top:6px;
    }
  }

  .answerSection {
    @mixin text_primary;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    padding-bottom:24px;
    padding-top:24px;
    text-align:center;
    width: 100%;
    align-items: flex-start;
    @media (--smartphone-and-tablet) {
      padding-bottom: 100px;
    }
  }
}
