@import "../../typography.css";
@import "../../mixins.css";

.component {
  width:100%;
  padding: 0 $spacingMd;
  height: 100vh;

  .contentSection {
    @mixin text_primary;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    padding-bottom:$spacingMd;
    padding-top:$spacingMd;
    text-align:center;
    max-height: 91%;
    width: 100%;
    align-items: flex-start;
    @media (--smartphone-and-tablet) {
      padding-bottom: 100px;
    }
  }

  .bottomSection {
    padding-top: $spacingSm;
    padding-bottom: $spacingHuge;

    .continueButton {
      @mixin background_primary;
      @mixin linkText;
      height:$spacingXl;
      width:240px;
    }

    @media (--smartphone-and-tablet) {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      @mixin background_primary;
      text-align: center;
      padding-top: $spacingSm;
      padding-bottom: $spacingSm;
    }
  }
}

.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  @media (--desktop) {
    justify-content: center;
  }
  @media (--smartphone-and-tablet) {
    justify-content: start;
    margin-top: $spacingLg;
  }
}
