@import "../../typography.css";
@import "../../mixins.css";

.success {
  @mixin headerText;
  max-width: 427px;
  margin: auto;
  padding: 60px 20px;
  text-align: center;
}
