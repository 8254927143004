@import "../../mixins.css";
@import "../../media.css";

.drawer {
  @mixin background_secondary;
  padding-bottom: 20px;
  flex-direction: column-reverse;
  align-items: center;


  @media (--desktop) {
    padding-bottom: 0;
  }

  .form {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $spacingMd;
    margin-left: 0;

    @media (--smartphone-and-tablet) {
      margin-top: $spacingXl;
      flex-direction: column-reverse;
    }
  }
}

.component {
  display: flex;
  max-width: 1200px;
  justify-content: center;
  margin: 0 auto;

  @media (--smartphone-and-tablet) {
    flex-direction: column-reverse;
  }
  @media (--desktop) {
    margin-top: $spacingXl;
  }
}

.form {
  @media (--desktop) {
    padding-left: $spacingXl;
    margin-left:  $spacingXl;
  }
}
